import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

/* const routes = [{
  path: '/',
  name: 'index',
  component: () => import('../views/index.vue'),
  meta: {
    title: '国创电力驾驶舱'
  }
},{
  path: '/ItemPage',
  name: 'ItemPage',
  component: () => import('../views/ItemPage.vue'),
  meta: {
    title: '蝶阀车间生产看板'
  }
}] */
const routes = [{
  path: '/',
  name: 'indexDiefa',
  component: () => import('../views/indexDevice.vue'),
  meta: {
    title: '微网优联生产看板'
  }
}]
// const routes = [{
//   path: '/',
//   name: 'indexDiefa',
//   component: () => import('../views/DZindexDevice.vue'),
//   meta: {
//     title: '蝶阀车间生产看板'
//   }
// }]
const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes
})

export default router